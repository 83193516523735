/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';

/* For ng-lazyload-image -> IE cant recover images without this lib */
import 'intersection-observer';

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

/** Issue with IE and FormData **/
// import 'formdata-polyfill';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
import 'classlist.js'; // Run npm install --save classlist.js.

/** IE10 and IE11 requires the following for the Reflect API. */
import 'core-js/es6/reflect';

/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
import 'core-js/es7/reflect';

/** need for 'includes' etc... **/
//#4128
import 'core-js/es7/array';
/**
Required to support Web Animations @angular/platform-browser/animations.
Needed for: All but Chrome, Firefox and Opera. http://caniuse.com/#feat=web-animation
**/
import 'web-animations-js'; // Run npm install --save web-animations-js.

(window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
(window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
(window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

(window as any).__Zone_enable_cross_context_check = true;

import 'zone.js/dist/zone'; // Included with Angular CLI.

//Polyfill library to use the Normalize function in IE11 
import 'unorm';